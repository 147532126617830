import { apiInstance } from "./networking";

export const getCaptcha = async () => {
  const { data = {} } = await apiInstance.get(
    `/primary/generate-captcha`
  );
  return data;
};

export const postCaptcha = async (payload) => {
console.log( "12   payload",payload)
  const { data = {} } = await apiInstance.post(
    `/primary/verify-captcha`, 
    payload
    );
    console.log(" 16  primary/verify-captcha",data)
    return data;
  };