import React, { useEffect, useState } from "react";
import Tab from "../../Components/kyc-components/Tab";
import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
import PanCardFrame1 from "./Frames/PanCardFrame1";
import styles from "./KYCHomeScreen.module.css";
import { useSelector } from "react-redux";
import AadhaarCardFrame from "./Frames/AadhaarCardFrame/AadhaarCardFrame";
import OtherDocsFrame from "./Frames/OtherDocsFrame";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import HiddenWrapper from "../../Components/global-components/hiddenWrapper/HiddenWrapper";
import InfoIcon from "@mui/icons-material/Info";
import CommonTemplate from "../../Components/global-components/CommonModal";
import Digitalformsixty from "./Frames/Digitalformsixty";
import { useNavigate } from "react-router-dom";
 
import Loader1 from "../../Components/loader-component/loader1"
 
// import { userLocation } from "../Redux/userData";
 
const KYCHomeScreen = () => {
  const navigate = useNavigate();
 
  // Create an AbortController instance
const controller = new AbortController();
// Obtain a reference to the AbortSignal
const signal = controller.signal;
 
const [controllerState , setControllerState] = useState(null)
 
const [cersaiLoader ,setCersaiLoader] = useState(false)
 
  const activeTabs = useSelector((state) => state.Tabs.activeTab);
  const activeSwitch = useSelector((state) => state.switchButton.activeSwitch);
  const { digitalForm60Id }=useSelector((state)=>state.digitalFormSixty.formSixtyData);
  const userDetails = useSelector((state) => state.userDetails.userData);
  const disableTab = useSelector((state) => state.Tabs.disableTab);
  console.log("disableTab",disableTab)
 
console.log("rerotur",userDetails.phoneNumber && (userDetails.totalKyc >= userDetails.limit))
useEffect(()=>{
  if (userDetails.phoneNumber && (userDetails.totalKyc >= userDetails.limit)) {
    console.log("inside")
     navigate("/home");
  }
},[])

useEffect(() => {
  // disable browser button
  return () => {
    setTimeout(() => {
      window.history.forward();
    }, 0);
  };
}, []);

 
  return (
    <>
      {cersaiLoader ? (
        <Loader1 setCersaiLoader={setCersaiLoader}
        controllerState={controllerState}
        />
      ) : (
        <div className={styles["kycHome_container"]}>
          <BannerImage>
            <LogoContainer />
          </BannerImage>
          <MsHome>
            <div className="bg-secondaryH md:h-[100%] md:overflow-hidden">
              <BottomCard>
                <>
                  <div className="md:flex-[0_0_auto]">
                    <label className="md:text-lg md:hidden sm:block">
                      <b>KYC Document</b>
                    </label>
                    <p className="text-sm md:hidden sm:block">
                      Let's fetch your kyc details using
                    </p>
                    <div className={"mb-3 md:hidden sm:block"}></div>
                    {!digitalForm60Id ? (
                      <div>
                        <Tab isDisabledTab={disableTab} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="md:flex-[1_0_0] md:overflow-hidden">
                    {activeTabs === "pan" && (
                      <div className="h-full w-full md:flex md:flex-col md:w-[70%]">
                        <PanCardFrame1 setCersaiLoader={setCersaiLoader}
                        setControllerState={setControllerState}
                        controller={controller}
                        signal={signal}
 
                        />
                      </div>
                    )}
 
                    {/* {activeTabs === "aadhaar" && (
                      <div className="w-full h-full  md:flex md:flex-col md:w-[70%]">
                        <AadhaarCardFrame />
                      </div>
                    )} */}
                    {activeTabs === "otherDocs" && (
                      <div className="w-full h-full   md:flex md:flex-col md:w-[70%]">
                        <OtherDocsFrame />
                      </div>
                    )}
                  </div>
                </>
              </BottomCard>
            </div>
          </MsHome>
        </div>
      )}
    </>
  );
};
 
export default KYCHomeScreen;


// import React, { useEffect, useState } from "react";
// import Tab from "../../Components/kyc-components/Tab";
// import BannerImage from "../../Components/login-components/banner-image/BannerImage";
// import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
// import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
// import PanCardFrame from "./Frames/PanCardFrame";
// import PanCardFrame1 from "./Frames/PanCardFrame1";
// import styles from "./KYCHomeScreen.module.css";
// import { useSelector } from "react-redux";
// import AadhaarCardFrame from "./Frames/AadhaarCardFrame/AadhaarCardFrame";
// import OtherDocsFrame from "./Frames/OtherDocsFrame";
// import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
// import HiddenWrapper from "../../Components/global-components/hiddenWrapper/HiddenWrapper";
// import InfoIcon from "@mui/icons-material/Info";
// import CommonTemplate from "../../Components/global-components/CommonModal";
// import Digitalformsixty from "./Frames/Digitalformsixty";
// import { useNavigate } from "react-router-dom";

// // import { userLocation } from "../Redux/userData";

// const KYCHomeScreen1 = () => {
//   const navigate = useNavigate();
//   const activeTabs = useSelector((state) => state.Tabs.activeTab);
//   const activeSwitch = useSelector((state) => state.switchButton.activeSwitch);
//   const { digitalForm60Id }=useSelector((state)=>state.digitalFormSixty.formSixtyData);
//   const userDetails = useSelector((state) => state.userDetails.userData);
//   // const [isModalOpen, setIsModalOpen] = useState(true);
//   // useEffect(() => {
//   //   if (activeTabs === "pan") setIsModalOpen(true);
//   // }, [activeTabs]);
// console.log("rerotur",userDetails.phoneNumber && (userDetails.totalKyc >= userDetails.limit))
// useEffect(()=>{
//   if (userDetails.phoneNumber && (userDetails.totalKyc >= userDetails.limit)) {
//     console.log("inside")
//      navigate("/home");
//   }
// },[])
//   return (
//     <div className={styles["kycHome_container"]}>
//       {/* {activeTabs === "pan" && (
//         <CommonTemplate
//           isTemplateOpen={isModalOpen}
//           closeTemplate={() => setIsModalOpen(false)}
//           modalLabel="Currently PAN (CKYC) services are down."
//           modalText={[
//             "You can complete your KYC through ",
//             <b>Aadhaar</b>,

//             " Or ",
//             <b>use other document to submit your kyc</b>,
//             ".",
//           ]}
//         />
//       )} */}
//       <BannerImage>
//         <LogoContainer />
//       </BannerImage>

//       <MsHome>
//         <div className="bg-secondaryH md:h-[100%] md:overflow-hidden">
//           <BottomCard>
//             <>
//               <div className="md:flex-[0_0_auto]">
//                 <label className="md:text-lg md:hidden sm:block">
//                   <b>KYC Document</b>
//                 </label>
//                 <p className="text-sm md:hidden sm:block">
//                   Let's fetch your kyc details using
//                 </p>
//                 <div className={"mb-3 md:hidden sm:block"}></div>
//                 {!digitalForm60Id?<div>
//                   <Tab />
//                 </div>:""
//                 }
//               </div>
//               <div className="md:flex-[1_0_0] md:overflow-hidden">
//                 {activeTabs === "pan" && (
//                   <div className="h-full w-full md:flex md:flex-col md:w-[70%]">
//                     {/* <PanCardFrame /> */}
//                     <PanCardFrame1 />
//                   </div>
//                 )}

//                 {/* {activeTabs === "aadhaar" && (
//                   <div className="w-full h-full  md:flex md:flex-col md:w-[70%]">
//                     <AadhaarCardFrame />
//                   </div>
//                 )} */}
//                 {activeTabs === "otherDocs" && (
//                   <div className="w-full h-full   md:flex md:flex-col md:w-[70%]">
//                     <OtherDocsFrame />
//                   </div>
//                 )}
//               </div>
//             </>
//           </BottomCard>
//         </div>
//       </MsHome>
//     </div>
//   );
// };

// export default KYCHomeScreen1;


