import React from 'react';
import './loader.css';

const Loader = ({balls_color}) => {
 return (

  <div className="loader">

   <div className="sub_loader">

    <div style={{background:balls_color}} className="circle_loader"></div>

    <div style={{background:balls_color}} className="circle_loader"></div>

    <div style={{background:balls_color}} className="circle_loader"></div>

    <div style={{background:balls_color}} className="circle_loader"></div>

   </div>

  </div>

 );

};



export default Loader;