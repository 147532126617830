import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../Components/global-components/CommonButton/CommonButton";
import ToggleButton from "../../../Components/kyc-components/ToggleButton";
import { useDispatch, useSelector } from "react-redux";
import { panUserdata, userPanNumber } from "../../Redux/panCardSlice";
import Loadingoverlay from "../../../Components/global-components/LoadingOverlay/Loadingoverlay";
import TextInput from "../../../Components/global-components/CommonTextInput/TextInput";
import { toggleButton } from "../../Redux/switchToggleSlice";
import { toggleTabs } from "../../Redux/slice";
import OtherDocsFrame from "./OtherDocsFrame";
import { getPanCardDetails ,getPanCardDetailsCersai } from "../../../services/panCard-service";
import { activeStepper } from "../../Redux/stepperSlice";
import BottomCard from "../../../Components/login-components/bottom-card/BottomCard";
import CommonDropDown from "../../../Components/global-components/CommonDropDown/CommonDropDown";
import AadhaarCardFrame from "./AadhaarCardFrame/AadhaarCardFrame";
import {
  gaFunction,
  MAX_ATTEMPT_TIME_LIMIT,
  scrollIntoViewFunc,
} from "../../../Router";
import { kycRefIdAddNewKyc } from "../../Redux/kycDetailsSlice";
import { showGlobalError } from "../../Redux/globalErrorSlice";
import Cookies from "universal-cookie";
// import { decrypt } from "../../../utils/encryption";
import { updateUserData } from "../../Redux/userData";
import { encrypt,decrypt } from "../../../utils/encryptionV2";
import ErrorBottomDrawer from "../../../Components/global-components/ErrorBottomDrawer/ErrorBottomDrawer";

// CAPTCHA SERVICE
import {getCaptcha , postCaptcha} from "../../../services/captcha-service"   
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";



const PanCardFrame = ({setCersaiLoader ,setControllerState ,controller, signal}) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const userDetails = useSelector((state) => state.userDetails.userData);
  const { panNumber: reduxPanNumber, dob: reduxDob } = userDetails;
  const [panValidationError, setpanValidationError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showOtherDocs, setShowOtherDocs] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [panNumber, setPanNumber] = useState(reduxPanNumber);
  const [dob, setDob] = useState(reduxDob);
  const [switchTab, setSwitchTab] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(true);
  const [errDrawer, setErrDrawer] = useState(false);

  // const userlocation = useSelector((state) => state.userDetails.geolocation);
  const istoggle = useSelector((state) => state.switchButton.activeSwitch);
  const activeTabs = useSelector((state) => state.Tabs.activeTab);
  const disableTab = useSelector((state) => state.Tabs.disableTab);
  console.log("tabs",useSelector((state)=> state.Tabs))
  console.log("disableTab",disableTab)
  const { channelUrl, mi_u,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const kyc_id = useSelector((state) => state.kycDetails.kycRefId);
  const dispatch = useDispatch();

  // const [userPanData, setUserPanData] = useState("");
  const [isLoading, setIsloading] = useState(false);
  function changedateformat(val) {
    const myArray = val.split("-");
    let year = myArray[0];
    let month = myArray[1];
    let day = myArray[2];
    let formatteddate = day + "-" + month + "-" + year;
    return formatteddate;
  }

  const [capthaValidationErr, setCapthaValidationErr] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [captchaVal, setCaptchaVal] = useState(null);
  const [captchaErrDrawer, setCaptchaErrDrawer] = useState(false)



  const panChangeHandler = (value) => {
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    value=value?.replace(/\s/g,"");
    if (value.length <= 10) {
      setPanNumber(value);
    }

    setpanValidationError(null);

    if (!regexPan.test(value)) {
      setpanValidationError("Invalid Pan Number");
    }
  };

   
   const panHandler = () => {
    setCersaiLoader(true)
    gaFunction("PAN (CkYC)", "PAN (CKYC) Proceed clicked");
    if (!panValidationError && dob && isDeclarationChecked && panNumber) {
      let data = JSON.stringify({
        panNo: panNumber,
        dob: changedateformat(dob),
        name: userDetails.name,
        mobile: userDetails.phoneNumber,
        channel: channelUrl,
        // txnId: mi_u,
        // transaction id
        txnId: mi_u || localStorage.getItem("txnId"),
        kyc_id,
        customerType: "I",
        // geolocation: userlocation, // redux
      });

      if (true) {
        setControllerState(controller) // SET CONTROLLEER HERE
        getPanCardDetailsCersai({enc_request: encrypt(data)}, encodeURIComponent(encrypt(`txnid=${mi_u}`)), signal )
          .then(function (response) {
            let decData= decrypt(response.enc_response)
            let extractedData = decData.data;
            console.log("extractedData from PAN",extractedData)
            // DEDUPESD CONCEPT ADDED IF PAN ALREDY EXIST THEN GO OT SUCCESS PAGE 
            if (extractedData?.exists) {
              setCersaiLoader(false)
              console.log("deduped workin in PAN")
              setIsloading(false);
              dispatch(activeStepper(3));
              navigate("/Success", {
                state: {
                  kyc_ref_id: extractedData?.kyc_id,
                  typeOfKyc: extractedData?.status,
                },
              });
            } 
            else {
              setCersaiLoader(false)
              console.log("ELSE PAN create new one")
              navigate("/panDetails", {
                state: {
                  isRouteAllowed: true,
                },
              });
              // let decData= decrypt(response.enc_response)
              // let extractedData = decData.data;
              dispatch(panUserdata(extractedData));
              dispatch(updateUserData({ name: extractedData.fullName }));
              dispatch(kycRefIdAddNewKyc(extractedData?.kyc_id || ""));
              dispatch(userPanNumber(panNumber));
              dispatch(activeStepper(1));
              setIsloading(false);
              setIsButtonLoading((prev) => !prev);
            }
          })
          .catch(function (error) {
            setCersaiLoader(false)
            console.log("133 catch main error", error)
            if (
              error?.response?.data?.message ===
              "No CKYC number found for the given pan number, click ok to proceed with Other Documents"
            ) {
              setTimeout(() => {
                setSwitchTab(true);
                dispatch(toggleTabs("otherDocs"));
              }, 1000);
            }
            // handle error
            else {
              console.log("144 handle cersai error on catch if/else", error)
              setIsloading(false);
              setErrDrawer(true);
              setSwitchTab(true);
              dispatch(toggleTabs("otherDocs"));
            }
            setIsloading(false);
            setIsButtonLoading((prev) => !prev);
          });
      } else {
        // setIsloading(false);
        // setIsButtonLoading((prev) => !prev);
        // dispatch(
        //   showGlobalError(
        //     "Please try again after 15 Minutes. Maximum attempt exceeded for PAN."
        //   )
        // );
        // cookies.set("pan_attempts", +cookies.get("pan_attempts"), {
        //   expires: new Date(Date.now() + MAX_ATTEMPT_TIME_LIMIT * 1000),
        // });
        // return;
      }
    } else {
      setCersaiLoader(false)
      setErrorMessage("Please fill all details");
      setIsloading(false);
      if (!panNumber) {
        scrollIntoViewFunc("pan_number");
      } else if (!dob) {
        scrollIntoViewFunc("dob");
      } else if (!isDeclarationChecked) {
        scrollIntoViewFunc("pan_dec_checkbox");
      }
      // setIsButtonLoading((prev) => !prev);
    }
  };

  const toggleButtonChangeHandler = (e) => {
    dispatch(toggleButton(e.target.checked));
    // if (!e.target.checked) dispatch(toggleTabs("otherDocs"));
    if (!e.target.checked)
      navigate("/digitalFormSixty", { state: { methodOfKyc: "pan" } });
  };

  const clickHandler = () => {
    gaFunction("OVD", "OVD Clicked");
    setShowOtherDocs(true);
    navigate("/kyc");
    dispatch(toggleTabs("otherDocs"));
    // dispatch(toggleButton(false));
  };

  useEffect(() => {
    dispatch(activeStepper(0));
  }, []);
  // useEffect(() => {
  //   dispatch(toggleButton(true));
  //   // return () => dispatch(toggleButton(true));
  // }, [dispatch]);
  useEffect(() => {
    let reduxPanValue=reduxPanNumber;
    reduxPanValue=reduxPanValue?.replace(/\s/g,"");
    if (reduxPanValue?.length == 10) {
      setPanNumber(reduxPanValue);
    }else{
      setPanNumber("");
    }
    setDob(reduxDob);
  }, [reduxDob, reduxPanNumber]);

   // CAPTCHA CODE ---STRART---
   useEffect(()=>{generateCaptcha()},[])
 
   function generateCaptcha () {
     getCaptcha().then((data)=>{
       let decResponse= decrypt(data.enc_response)
       sessionStorage.setItem('sessionID', decResponse?.capthchaSessionID);
       let svgString = decResponse?.captchaSvg?.replace(/\\/g, '');
       const svgDataUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
       setImgUrl(svgDataUrl)
     })
   }
    
   function captchaChangeHandler(value) {
   value=value?.replace(/\s/g,"");
    if (value.length <= 6) {
     setCaptchaVal(value);
   }
   }

   function regeneratCaptcha(e) {
     e.preventDefault()
     generateCaptcha()
     setCaptchaVal("")
     setCapthaValidationErr("")
   }

   const captchaHandler = (btnType) => {
     if(captchaVal?.length === 6) {
       console.log(captchaVal)
       const storedValue = sessionStorage.getItem('sessionID');
       if(captchaVal === storedValue ) {
         btnType === 'panHandler' ? panHandler() : clickHandler()
         sessionStorage.removeItem('sessionID');
       } else {
         setCaptchaErrDrawer(true)
         generateCaptcha()
         setCaptchaVal("")
       }
   } else {
    let errmsg =  btnType === 'panHandler' ? 'Proceed' : 'OVD'
     setCaptchaError(true)
     scrollIntoViewFunc("captcha_number");
     setCapthaValidationErr(`Please enter valid captcha to ${errmsg}.`)
    //  setIsButtonLoading((prev) => !prev);
   } }




  return (
    <>
      {isLoading ? (
        <Loadingoverlay />
      ) : (
        <>
          {showOtherDocs || istoggle === false ? (
            // <OtherDocsFrame />
            navigate("/digitalFormSixty", { state: { methodOfKyc: "pan" } })
          ) : (
            <>
              {!showOtherDocs && (
                <>
                  <div className="mt-[16px]"></div>
                  
                  <div className="  md:overflow-scroll md:p-0 ">
                    {!(subChannel==="web" && mi_u.slice(0,3)==="KY3") &&
                    <div className="flex   gap-[10px]  items-center max-w-[100%]">
                      <p>Do you have a valid PAN Number?</p>
                      <ToggleButton
                        onChange={(e) => toggleButtonChangeHandler(e)}
                        checked={istoggle}
                      />
                    </div>
}
                    <div className="mt-5">
                      <label
                        htmlFor="first_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                      >
                        PAN No.
                      </label>
                      <TextInput
                        placeholder={"PAN Number"}
                        width="md:w-[300px]"
                        value={panNumber}
                        onChange={(e) =>
                          panChangeHandler(e.target.value.toUpperCase())
                        }
                        success={
                          panValidationError || panNumber?.length <= 0
                            ? false
                            : true
                        }
                        id="pan_number"
                        required
                      />
                      {panValidationError && (
                        <p className="text-red-500 text-sm">
                          {panValidationError}
                        </p>
                      )}
                    </div>
                    <div className="mt-5">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700">
                        Date of birth.
                      </label>
                    </div>
                    <input
                      id="dob"
                      className=" border border-gray-500 border-opacity-30 placeholder-gray-500 placeholder-opacity-50 w-full md:max-w-[300px] p-[8px] uppercase"
                      type="date"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                    {errorMessage && (
                      <p className="text-red-500 mt-2">{errorMessage}</p>
                    )}
                    <div className="flex mt-2">
                      <input
                        id="pan_dec_checkbox"
                        type="checkbox"
                        value={isDeclarationChecked}
                        checked={isDeclarationChecked}
                        className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                        onChange={(e) =>
                          setIsDeclarationChecked(e.target.checked)
                        }
                      />
                      <label
                        htmlFor="link-checkbox"
                        className="ml-2 text-sm font-medium text-black "
                      >
                        I hereby provide my consent to HDFC ERGO General
                        Insurance Company Limited to retrieve/ verify my KYC
                        documents like Address /ID proof and photograph, from
                        the CERSAI portal against my PAN No. I understand that
                        the said KYC documents shall be relied upon by the
                        Company for processing my policy/refunds/ Claims.
                      </label>
                    </div>
{/* CPATCHA CODE START */}
                    <div className="captcha-div" style={{ display: "flex" , marginTop:"15px"}}>
                        <img
                          src={imgUrl}
                          style={{ border:"1px solid #d1d5db", padding :"5px", borderRadius: "10px", marginRight: "12px" }}
                          alt="Captcha Img"
                        />
                        <Tooltip
                        style={{margin:"20px 15px 0px 0px"}}
                 id="button-report1"
                 title={"Captcha is mandate from Infosec point of view"}
               >
                 <InfoIcon sx={{ color: "black", fontSize: "17px" }} />
               </Tooltip>
                         <SyncOutlinedIcon sx={{ color: "black", 
                         fontSize: "25px", marginTop:"15px",
                         marginRight:"10px" , cursor:"pointer" }} onClick={(e)=>regeneratCaptcha(e)} />
                        <TextInput
                          placeholder={"Enter Captcha"}
                          // width="md:w-[300px]"
                          value={captchaVal}
                          onChange={(e) => captchaChangeHandler(e.target.value)}
                          success={captchaVal <= 0 ? false : true}
                         //  id="captcha_number"
                          required
                        />
                              </div>
                         {captchaError && (
                            <p className="text-red-500 text-sm" style={{marginTop:"5px"}}>
                              {capthaValidationErr}
                            </p>
                          )}
                          
                          <div id="captcha_number" style={{margin:"20px"}}></div>
{/* CPATCHA CODE END */}

                  </div>
                  <div className="w-full mt-10 md:mt-0 md:w-[300px] flex md:pb-1">
                    {/* <CommonButton
                      label="Proceed"
                      filled
                      arrow
                      loading={isButtonLoading}
                      id={"toCompletePanKyc"}
                      onClickHandler={() => {
                        setIsloading(true);
                        panDetailsHandler();
                        setIsButtonLoading((prev) => !prev);
                      }}
                    /> */}
                      <CommonButton
                          label="Proceed"
                          filled
                          arrow
                          loading={isButtonLoading}
                          id={"toCompletePanKyc"}
                          onClickHandler={() => {
                           //  setIsloading(true);
                            // panDetailsHandler();
                            captchaHandler('panHandler')
                            // setIsButtonLoading((prev) => !prev);
                          }}
                        />
                  </div>
                  <div className="mt-[16px] md:mt-0"></div>
                  {!(subChannel==="web" && mi_u.slice(0,3)==="KY3") &&
                  <div
                    className="text-center md:text-left underline text-blue cursor-pointer md:mt-1.5 mb-3 md:mb-0"
                    style={{ color: "blue" }}
                  >
                    {/* <p onClick={clickHandler} id="otherDocsKyc">
                      Use other documents to submit your KYC
                    </p> */}
                   <p onClick={() => captchaHandler('ovdHandler')} id="otherDocsKyc">
                              Use other documents to submit your KYC
                            </p>
                  </div>
}
                </>
              )}
            </>
          )}
          {errDrawer && (
            <ErrorBottomDrawer
              extraNotes = "Oops! Something Went Wrong"
              errorMessage="Cersai is currently unavailable. Please try again later or use Aadhaar or OVD journey for KYC."
              onClickHandler={() => setErrDrawer(false)}
            />
          )}
          {captchaErrDrawer && (
            <ErrorBottomDrawer
              extraNotes = "Error"
              errorMessage="CAPTCHA verification failed!"
              onClickHandler={() => setCaptchaErrDrawer(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default PanCardFrame;
