import React, { useState, useEffect } from "react";
import "./loader.css";
import Loader from "./loader";
import { useDispatch } from "react-redux";
import { toggleTabs } from "../../Screens/Redux/slice";

const Loader1 = ({ setCersaiLoader, controllerState,isMaruti }) => {
  let miliSec = 3000;
  const dispatch = useDispatch();

  const [loading_status, setLoadingStatus] = useState(
    "Please wait while we process your request…"
  );
  const [getBtn, SetGEtBtn] = useState(false);

  function clickHandler() {
    console.log("clickHandler on loader", controllerState);
    setCersaiLoader(false);
    dispatch(toggleTabs("otherDocs"));

    // To cancel the request, call the abort() method on the controllerState
    controllerState.abort();
  }

  function loadermessagechange() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log("3 sec");
        setLoadingStatus(`Connecting to CERSAI for verification. This might take a moment…`);
      }, miliSec);

      setTimeout(() => {
        setLoadingStatus(`CERSAI is verifying your details…`);
      }, miliSec + 2000);

      setTimeout(() => {
        SetGEtBtn(true);
        setLoadingStatus(`CERSAI is taking longer than usual. We're still on it…`);
      }, miliSec + 10000);

      resolve("hi");
    });
  }

  useEffect(() => {
    loadermessagechange();
  }, []);

  return (
    <div className="loader">
      <div className="sub_loader1">

        <div className="loaderMiddleText">
          <Loader balls_color={`${isMaruti?"#113569":"#e41e26"}`} />

          <p style={{ marginTop: "30px", textAlign: "center", width: "95vw" }}>
            {loading_status}
          </p>
          {getBtn && (
            <>
              <p style={{ marginTop: "30px", textAlign: "center", width: "95vw" }}>
                {
                  "If you don’t want to wait, you can try an alternate verification method."
                }
              </p>
              <div
                class="text-center md:text-left text-blue cursor-pointer md:mt-1.5 mb-3 md:mb-0"
                style={{
                  marginTop: "30px",
                  textAlign: "center",
                  width: "95vw",
                }}
              >
                <a
                  onClick={clickHandler}
                  id="Resolve"
                  class="inline-flex w-2/5 items-center justify-center py-2 px-2 text-sm font-medium text-center text-white bg-[#E94D51] rounded-md border border-[#E94D51]  focus:ring-2 focus:outline-none focus:ring-[#E94D51]"
                >
                  Try Another Method
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Loader1;
